
<template>
  <v-dialog v-model="openDialog" max-width="500px">
    <v-card>
      <v-toolbar
        :color="(toolBarColor ? toolBarColor : 'primary') + ' darken-1'"
        dark
      >
        <v-card-title>
          {{ dialogTitle ? dialogTitle : $t("delete") }}
        </v-card-title>
      </v-toolbar>
      <v-card-title>
        {{ dialogText ? dialogText : $t("AreYouSureAboutTheOperation") }}
      </v-card-title>
      <v-card-actions>
        <v-btn color="red darken-1" text @click="buttonClick" :disabled="loading" :loading="loading">
          {{ okButtonText ? okButtonText : $t("ok") }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text @click="closeClick">
          {{ noButtonText ? noButtonText : $t("cancel") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>



<script>
export default {
  props: [
    "onClicked",
    "onClose",
    "payload",
    "openDialog",
    "dialogTitle",
    "dialogText",
    "okButtonText",
    "noButtonText",
    "toolBarColor",
  ],
  data: () => ({
    loading: false,
  }),
  watch: {
    openDialog: {
      handler() {
        this.loading = false;
      }
    }
  },
  methods: {
    buttonClick() {
      this.loading = true;
      if (this.onClicked) {
        this.onClicked(this.payload);
      }
    },
    closeClick() {
      if (this.onClose) {
        this.onClose();
      } else {
        this.openDialog = false;
      }
    },
  },
};
// how to use
// <confirm-dialog
//   :openDialog="dialogDelete"
//   :onClicked="deleteItemConfirm"
//   :onClose="closeDelete"
// ></confirm-dialog>
</script>
