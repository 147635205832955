<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :loading-text="$t('loading')"
      :page.sync="page"
      :items-per-page="
        itemsPerPage2 != null ? (itemsPerPage = itemsPerPage2) : itemsPerPage
      "
      hide-default-footer
    >
      <template v-for="(header, index) in headers.filter(item=>item.tip)" v-slot:[`header.${header.name}`]="slotProps">
       <span :key="header.key" v-bind="slotProps"> {{ header.text }}
       <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>{{ header.tip }}</span>
          </v-tooltip>
       </span>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr
          v-for="(item, i) in items"
          :key="i"
          @click="$emit('rowClick', item)"
          :class="
              $vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-table-row'
                : ''
            "
        >
          <td
            v-for="header in headers.filter((f) => f.value !== 'actions')"
            :key="header.value"
            :style="header.width ? 'width:'+header.width : ''"
            :class="
                ($vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row'
                  : 'text-start') +' ' + header.cellClass
              "
          >
            <v-row>
              <span class="hidden-sm-and-up">
                {{ header.text }}
              </span>

              <span v-if="header.map">
                  {{ header.map(item[header.value]) }}
                </span>

              <span v-else-if="header.type == 'select'">
                <v-chip
                  v-if="header.chip"
                  :color="getSelectcolor(item, header)"
                >
                  {{ getSelectText(item[header.value], header) }}
                </v-chip>
                <span v-else>
                  {{ getSelectText(item[header.value], header) }}
                </span>
              </span>

              <div v-else-if="header.type == 'multiselect'" class="pa-2">
                <v-chip
                  class="ml-1 mt-1"
                  v-for="(guid, ind) in item[header.value]"
                  :key="ind"
                >
                  {{ getSelectText(guid, header) }}

                  <!-- {{ guid }} -->
                </v-chip>
              </div>

              <v-switch
                v-else-if="header.type == 'switch'"
                v-model="item[header.value]"
                :readonly="header.readonly"
              >
              </v-switch>

              <v-text-field
                v-else-if="header.type == 'textfield'"
                v-model="item[header.value]"
              ></v-text-field>

              <v-textarea
                v-else-if="header.type === 'longtext'"
                v-model="item[header.value]"

              ></v-textarea>

              <v-text-field
                v-else-if="header.type == 'email'"
                v-model="item[header.value]"
                :rules="[rules.email]"
              ></v-text-field>

              <v-checkbox
                v-else-if="header.type == 'checkbox'"
                disabled
                v-model="item[header.value]"
              >
              </v-checkbox>
              <span v-else-if="header.type == 'date'">
                {{ item[header.value] | format_date_time }}
              </span>

              <span v-else-if="header.type == 'number'">
                {{ item[header.value] | number(header.format) }}
              </span>
              <span v-else>
                {{ item[header.value] }}
              </span>

              <v-tooltip v-if="header.mark && header.mark(item)" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                </template>
                <span>تم تغيير هذا الرمز</span>
              </v-tooltip>
            </v-row>
          </td>

          <td
            v-if="actionsHeader"
            class=""
            :style="$vuetify.breakpoint.xsOnly ? '' : 'width: 175px'"
            :class="
                $vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row'
                  : 'text-end'
              "
          >
            <span class="hidden-sm-and-up"> {{ $t("actions") }}</span>
            <div>
              <template v-for="(template, j) in actionsHeader.templates">
                <v-tooltip top :key="j" v-if="template.type == 'btn'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="template.click(item)"
                      v-bind="attrs"
                      v-on="on"
                      :color="template.color"
                    >
                      <v-icon> {{ template.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ template.text }}</span>
                </v-tooltip>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot
          v-if="
            footerItems && footerItems.filter((f) => f.showSummary).length > 0
          "
        >
        <tr
          class="primary--text"
          :class="
              $vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-table-row'
                : ''
            "
        >
          <template v-for="(item, i) in footerItems">
            <td v-if="i == 0" :key="i" class="d-none d-sm-flex pa-3">
              {{ $t("total") }}
            </td>
            <td
              v-else-if="!item.showSummary"
              :key="i"
              :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"
            ></td>
            <td
              v-else
              :key="i"
              :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
            >
                <span class="hidden-sm-and-up">
                  {{ $t("total") + " " + item.text }}
                </span>
              {{ sumTotals(item.value) | number("0,0") }}
            </td>
          </template>
        </tr>
        </tfoot>
      </template>

        {{$slots}}

    </v-data-table>
    <v-divider/>
    <slot name="footer"></slot>
    <v-row v-if="!!(showFooter || showFooter == null)" class="mt-2">
      <v-col cols="12" sm="2" align-self="center">
        <span class="px-5">
          {{ $t("count") }} :
          {{ items && items.length > 0 ? items.length : "" }}
        </span>
      </v-col>
      <v-col align-self="end">
        <v-pagination
          class="mb-2"
          v-model="page"
          :length="pageLength ? pageLength : 0"
        ></v-pagination>
      </v-col>
      <v-col align-self="center" cols="12" sm="2">
        <!-- <div class="elevation-2 pa-1 rounded"> -->
        <v-select
          dense
          outlined
          :label="$t('itemPerPage')"
          :items="itemPerPageSelect"
          item-text="text"
          item-value="value"
          v-model="itemsPerPage"
          hide-details=""
        >
        </v-select>
        <!-- </div> -->
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: [
    "items",
    "headers",
    "search",
    "loading",
    "itemsPerPage2",
    "showFooter",
  ],

  data() {
    return {
      page: 1,
      itemsPerPage: 50,
      itemPerPageSelect: [
        {text: "5", value: 5},
        {text: "10", value: 10},
        {text: "15", value: 15},
        {text: "50", value: 50},
        {text: this.$t("all"), value: -1},
      ],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        confirmPassword: () =>
            this.editedItem.password === this.editedItem.password_confirmation ||
            this.$t("users.notMatchPassword"),
        email: (v) => /.+@.+\..+/.test(v) || this.$t("users.validEmail"),
        length: (value) =>
            (value != null && value.length < 255) ||
            this.$t("ThisFieldIsRequired"),
      },
    };
  },
  computed: {
    actionsHeader() {
      return this.headers.filter((f) => f.value == "actions")[0];
    },
    footerItems() {
      var list = [];
      for (let i = 0; i < this.headers.length; i++) {
        var header = this.headers[i];

        if (header.type == "number") {
          list.push({
            showSummary: true,
            value: header.value,
            text: header.text,
          });
        } else {
          list.push({showSummary: false, text: header.text});
        }
      }
      // console.log(list);
      return list;
    },
    pageLength() {
      if (this.itemsPerPage == -1) {
        return 1;
      } else {
        try {
          var div = this.items.length / this.itemsPerPage;
          return Number.isInteger(div) ? div : (div | 0) + 1;
        } catch (error) {
          return 1;
        }
      }
    },
  },
  watch: {
    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },
  methods: {
    getSelectText(guid, header) {
      var text = "";
      try {
        text = header.items.filter((f) => f.value == guid)[0].text;
      } catch (error) {
        text = "";
      }
      // console.log(text);
      return text;
    },
    getSelectcolor(guid, header) {
      var text = "";
      try {
        text = header.items.filter((f) => f.value == guid)[0].color;
      } catch (error) {
        text = "";
      }
      return text;
    },
    deleteClick(item) {
      if (this.onDelete) {
        this.onDelete(item);
      }
    },
    sumTotals(value) {
      var total = this.items.reduce((a, b) => a + (b[value] || 0), 0);
      return total;
    },
    rowClick(item) {
      console.log(item);
    },
  },
};
</script>
